import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import i18n from './i18n';
import en from 'vuetify/src/locale/en.ts';
import fr from 'vuetify/src/locale/fr.ts';

Vue.use(Vuetify);

export default new Vuetify({
    //lang: {
    //    t: (key, ...params) => i18n.t(key, params)
    //},
    lang: {
        locales: { en, fr },
        current: 'en',
    },
    theme: {
        themes: {
            light: {
                primary: '#158ab9', // blue
                secondary: '#10739d', // dark blue
                accent: '#116183', // darker blue
            },
        },
    },
});
