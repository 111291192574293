<template>
    <v-dialog v-model="value" fullscreen hide-overlay persistent transition="fade-transition">
        <v-container fill-height fluid class="pa-0 ma-0 black" id="container" v-intersect="onIntersect" @click="muteVideo(false)">
            <div id="bg1">
                <video autoplay muted loop playsinline id="splash-video">
                    <source src="@/assets/videos/intro.mp4" type="video/mp4">
                </video>
            </div>
            <div id="bg2"></div>
            <v-row no-gutters id="content">
                <v-col cols="12">
                    <v-container fill-height fluid class="secondary-font">
                        <v-row no-gutters align="center" justify="center">
                            <!--<v-col cols="12" align="center">
                                <v-img src="@/assets/img/logo+text_light.svg" id="logo"></v-img>
                            </v-col>-->
                            <v-col cols="12">
                                <v-row align="center" justify="center">
                                    <v-col cols="auto">
                                        <v-btn
                                            tile large elevation="8"
                                            @click="enter('fr')"
                                            color="primary"
                                        >Français</v-btn>
                                    </v-col>
                                    <v-col cols="auto" class="white--text">
                                        |
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn
                                            tile large elevation="8"
                                            @click="enter('en')"
                                            color="primary"
                                        >English</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" align="center" class="text-caption text-uppercase accent--text my-6">
                                <div class="text">
                                    Ce site est destiné à un public adulte. 
                                    En accédant à ce site vous confirmez que vous êtes âgé de 18 ans et plus.
                                </div>
                                <div class="text">
                                    This site is intended for an adult audience. 
                                    By accessing this site you confirm that you are 18 years of age or older.
                                </div>
                            </v-col>
                            <v-col cols="12" align="center" class="text-caption primary--text">
                                &copy; {{ new Date().getFullYear() }} {{ _env.VUE_APP_TITLE }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-dialog>
</template>

<script>
    export default {
        name: "splash-dialog",
        components: {},
        data: () => ({
            value: true
        }),
        methods: {
            enter(locale) {
                //console.log(locale);
                this.value = false;
                this.$i18n.locale = locale;
                this.$vuetify.lang.current = locale;
                this.$emit("enter");
            },
            muteVideo(mute = true) {
                console.log("muteVideo", mute)
                const video = document.getElementById('splash-video');
                video.muted = mute;
            },
            onIntersect(entries, observer, isIntersecting) {
                if(!isIntersecting) {
                    this.muteVideo();
                }
            }
        }
    };
</script>

<style scoped>
    #logo {
        max-width: 320px;
    }

    #container {
        position: relative;
        overflow: hidden;
    }

    #bg1 {
        position: absolute;
        z-index: 1;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        background-color: #ffffff;
    }
    #bg1 video {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom center;
        bottom: 5%;
    }

    #bg2 {
        position: absolute;
        z-index: 1;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        background-image: url("@/assets/img/girl-02.png");
        background-size: auto 100%;
        background-position-x: 1100px;
        opacity: 0.5;
    }

    #content {
        position: fixed;                    /* Fixes the content relative to the viewport */
        bottom: 0;                          /* Aligns it to the bottom of the screen */
        left: 0;
        width: 100%;
        z-index: 2;                         /* Ensures it appears above the video backgrounds */
        background: linear-gradient(to top, white 50%, transparent);  /* White at the bottom fading to transparent at the top */
        padding: 20px;                      /* Adjust padding as needed for spacing */
    }

    @media screen and (min-width: 960px) and (max-width: 1264px) { /* md */
        #bg2 {
            background-position-x: 700px;
        }
    }
    @media screen and (min-width: 600px) and (max-width: 960px) { /* sm */
        #bg1 video {
            bottom: 7.5%;
        }
        #bg2 {
            background-position-x: 500px;
        }
        .text {
            font-size: 10px;
            line-height: 14px;
        }
    }
    @media screen and (max-width: 600px) { /* xs */
        #logo {
            max-width: 350px;
        }
        #bg1 video {
            bottom: 10%;
        }
        #bg2 {
            background-position-x: 200px;
        }
        .text {
            font-size: 8px;
            line-height: 12px;
        }
    }
</style>