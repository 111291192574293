<template>
    <v-hover>
        <template v-slot:default="{ hover }">
            <div>
                <v-card
                    elevation="8"
                    :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }"
                    :loading="fetching"
                    link tile
                    @click="fetch"
                    v-show="!button"
                    class="card"
                    dark
                >
                    <!--<div
                        v-if="origin == 'schedule' && !!item.availability"
                        class="diagonal-badge"
                        :class="{ 'primary darken-1': item.availability == '1', 'secondary': item.availability == '2' }"
                    >
                        <v-icon left small>{{ item.availability == '1' ? 'mdi-home' : 'mdi-phone' }}</v-icon>
                        {{ $t("availabilities." + item.availability) }}
                    </div>-->
                    <v-img color="secondary" height="440px" tile :src="'./uploads/' + item.photo" v-if="!!item && item.photo">
                        <v-row no-gutters class="fill-height background" align="end" justify="center">
                            <v-col
                                cols="12"
                                class="text-center text-h4 title"
                            >
                                <v-rating
                                    v-if="isJobApplication"
                                    :value="!!item ? item.rating : null" readonly
                                    length="5" size="32"
                                    color="white"
                                ></v-rating>
                                <div class="secondary-font text-truncate pa-4">
                                    {{ title }}
                                </div>
                                <!--<v-divider v-if="origin == 'schedule' && !!item.schedule"></v-divider>-->
                                <div v-if="origin == 'schedule' && !!item.schedule" class="pb-2">
                                    <v-chip v-for="(schedule, s) in item.schedule" :key="'schedule_time_' + s" outlined color="white" label class="text-body-2 font-weight-bold mx-1">
                                        <!--<v-icon left small>{{ item.availability == '1' ? 'mdi-home' : 'mdi-phone' }}</v-icon>-->
                                        {{ schedule.start }} - {{ schedule.end }}
                                    </v-chip>
                                </div>
                            </v-col>
                        </v-row>
                    </v-img>
                    <v-sheet :color="!!item && item.is_active ? 'secondary darken-2' : 'secondary'" height="440px" v-else></v-sheet>
                    <v-fade-transition>
                        <v-overlay
                            v-if="hover"
                            absolute
                            color="secondary"
                            z-index="4"
                            @click="fetch"
                        >
                            <v-btn icon x-large :loading="fetching" disabled>
                                <v-icon size="40">mdi-eye</v-icon>
                            </v-btn>
                        </v-overlay>
                    </v-fade-transition>

                    <!-- dialog -->
                    <v-dialog
                        v-model="dialog"
                        max-width="1000px"
                        :fullscreen="$vuetify.breakpoint.xs"
                        scrollable
                        @keydown.esc="overlay.value = false"
                    >
                        <v-form ref="form" @submit.prevent @submit="save">
                            <v-card flat id="wrapper" :tile="$vuetify.breakpoint.xs">
                                <v-toolbar flat id="header" height="100px" color="primary">
                                    <v-toolbar-title class="secondary-font text-h3 px-2 white--text">
                                        <span class="text-truncate">{{ title }}</span>
                                    </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon tile outlined text large @click="dialog = false" class="mr-1" dark>
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-text id="content" ref="dialog-content">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="5" md="6" class="column accent">
                                            <v-carousel hide-delimiter-background show-arrows-on-hover cycle height="100%">
                                                <v-carousel-item v-for="(photo, p) in form.photos" :key="'photo_' + p">
                                                    <v-img :src="photo.indexOf('base64') !== -1 ? photo : './uploads/' + photo" height="100%">
                                                        <div class="overlay-action" @click="overlay.value = true; overlay.current = photo;"></div>
                                                        <v-container fluid v-if="isAdminUser">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn
                                                                        v-on="on" small fab tile class="mr-1"
                                                                        @click="p !== 0 ? form.photos.unshift(form.photos.splice(p, 1)[0]) : null"
                                                                    >
                                                                        <v-icon>{{ p === 0 ? "mdi-star" : "mdi-star-outline" }}</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                {{ $t("pages.hostesses.main-photo") }}
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" small fab tile color="error" class="mx-1" @click="form.photos.splice(p, 1)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                {{ $t("delete") }}
                                                            </v-tooltip>
                                                        </v-container>
                                                    </v-img>
                                                </v-carousel-item>
                                            </v-carousel>
                                            <ImageCarouselOverlay
                                                v-if="!!form.photos && form.photos.length > 0"
                                                :value="overlay.value"
                                                :images="form.photos"
                                                :current="overlay.current"
                                                path="./uploads/"
                                                @close="overlay.value = false"
                                            ></ImageCarouselOverlay>
                                            <input v-if="isAdminUser" type="file" accept=".jpg,.jpeg,.png" v-show="false" ref="photos" multiple @change="addPhotos" />
                                            <v-btn
                                                v-if="isAdminUser"
                                                absolute top right class="action" tile
                                                @click="$refs.photos.click()"
                                                :disabled="saving"
                                            >
                                                <v-icon left>mdi-plus</v-icon>{{ $t("add") }}
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="6" :class="{ 'column': $vuetify.breakpoint.smAndUp }">
                                            <v-tabs
                                                v-model="tab"
                                                background-color="accent"
                                                active-class="secondary"
                                                slider-color="primary"
                                                grow
                                                dark
                                            >
                                                <v-tab v-if="!isEditor"><v-icon left size="20">mdi-text</v-icon>{{ $t("pages.hostesses.form.description") }}</v-tab>
                                                <v-tab v-if="isEditor"><v-icon left size="20">mdi-earth</v-icon>{{ $t("pages.hostesses.tabs.public") }}</v-tab>
                                                <v-tab v-if="isEditor"><v-icon left size="20">mdi-lock</v-icon>{{ $t("pages.hostesses.tabs.private") }}</v-tab>
                                                <v-tab v-if="!isJobApplication"><v-icon left size="20">mdi-clock</v-icon>{{ $t("pages.hostesses.tabs.schedule") }}</v-tab>
                                            </v-tabs>
                                            <v-tabs-items v-model="tab">
                                                <v-tab-item v-if="!isEditor">
                                                    <v-row no-gutters>
                                                        <v-col cols="12" v-if="!!form['description_' + $i18n.locale]">
                                                            <div class="text-h5 pa-4">
                                                                <span class="main-font primary--text text--darken-2">{{ form['description_' + $i18n.locale] }}</span>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider></v-divider>
                                                    <v-row no-gutters>
                                                        <v-col cols="6">
                                                            <v-list two-line>
                                                                <v-list-item v-if="form.age > 0">
                                                                    <v-list-item-content>
                                                                        <div class="text-h5">
                                                                            <span class="main-font primary--text">{{ form.age }}</span>
                                                                        </div>
                                                                        <v-list-item-subtitle>{{ $t("pages.hostesses.form.age") }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <div class="text-h5">
                                                                            <span class="main-font primary--text">{{ height }}</span>
                                                                        </div>
                                                                        <v-list-item-subtitle>{{ $t("pages.hostesses.form.height") }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <div class="text-h5">
                                                                            <span class="main-font primary--text">{{ !!form.eye_color ? $t("eye_colors." + form.eye_color) : "—" }}</span>
                                                                        </div>
                                                                        <v-list-item-subtitle>{{ $t("pages.hostesses.form.eye_color") }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <div class="text-h5">
                                                                            <span class="main-font primary--text">{{ !!form.bra_size ? form.bra_size : "—" }}</span>
                                                                        </div>
                                                                        <v-list-item-subtitle>{{ $t("pages.hostesses.form.bra_size") }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-list two-line>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <div class="text-h5">
                                                                            <span class="main-font primary--text">{{ form['nationality_' + $i18n.locale] ? form['nationality_' + $i18n.locale] : "—" }}</span>
                                                                        </div>
                                                                        <v-list-item-subtitle>{{ $t("pages.hostesses.form.nationality") }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <div class="text-h5">
                                                                            <span class="main-font primary--text">{{ form.weight }} lbs</span>
                                                                        </div>
                                                                        <v-list-item-subtitle>{{ $t("pages.hostesses.form.weight") }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <div class="text-h5">
                                                                            <span class="main-font primary--text">{{ !!form.hair_color ? $t("hair_colors." + form.hair_color) : "—" }}</span>
                                                                        </div>
                                                                        <v-list-item-subtitle>{{ $t("pages.hostesses.form.hair_color") }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                                <v-tab-item v-if="isEditor">
                                                    <v-container fluid>
                                                        <v-row dense>
                                                            <v-col cols="12" v-if="!isJobApplication">
                                                                <v-text-field
                                                                    :label="$t('pages.hostesses.form.nickname')"
                                                                    v-model="form.nickname"
                                                                    outlined :readonly="readonly"
                                                                    :rules="[ $data.customRules.required ]" maxlength="15"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6" v-if="!isJobApplication">
                                                                <v-textarea
                                                                    :label="$t('pages.hostesses.form.description') + ' (EN)'"
                                                                    v-model="form.description_en"
                                                                    outlined counter="200" rows="3" :readonly="readonly"
                                                                    :rules="[ $data.customRules.required ]" maxlength="200"
                                                                ></v-textarea>
                                                            </v-col>
                                                            <v-col cols="6" v-if="!isJobApplication">
                                                                <v-textarea
                                                                    :label="$t('pages.hostesses.form.description') + ' (FR)'"
                                                                    v-model="form.description_fr"
                                                                    outlined counter="200" rows="3" :readonly="readonly"
                                                                    :rules="[ $data.customRules.required ]" maxlength="200"
                                                                ></v-textarea>
                                                            </v-col>
                                                            <v-col cols="6" v-if="!isJobApplication">
                                                                <v-text-field
                                                                    :label="$t('pages.hostesses.form.nationality') + ' (EN)'"
                                                                    v-model="form.nationality_en"
                                                                    outlined :readonly="readonly"
                                                                    :rules="[ $data.customRules.required ]" maxlength="20"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6" v-if="!isJobApplication">
                                                                <v-text-field
                                                                    :label="$t('pages.hostesses.form.nationality') + ' (FR)'"
                                                                    v-model="form.nationality_fr"
                                                                    outlined :readonly="readonly"
                                                                    :rules="[ $data.customRules.required ]" maxlength="20"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" v-if="isJobApplication">
                                                                <v-text-field
                                                                    :label="$t('pages.hostesses.form.nationality')"
                                                                    v-model="form.nationality_en"
                                                                    outlined readonly
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <!--<v-text-field
                                                                    :label="$t('pages.hiring.form.height')"
                                                                    v-model="form.height"
                                                                    v-mask="'###'"
                                                                    outlined suffix="cm" :readonly="readonly"
                                                                    :rules="[ $data.customRules.required, $data.customRules.integer ]"
                                                                ></v-text-field>-->
                                                                <v-select
                                                                    :label="$t('pages.hiring.form.height')"
                                                                    v-model="form.height"
                                                                    :items="heights"
                                                                    outlined
                                                                    :rules="[ $data.customRules.required ]"
                                                                ></v-select>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-text-field
                                                                    :label="$t('pages.hiring.form.weight')"
                                                                    v-model="form.weight"
                                                                    v-mask="'###'"
                                                                    outlined suffix="lbs" :readonly="readonly"
                                                                    :rules="[ $data.customRules.required, $data.customRules.integer ]"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-select
                                                                    :label="$t('pages.hiring.form.eye_color')"
                                                                    v-model="form.eye_color"
                                                                    :items="[ 'blue', 'brown', 'gray', 'green', 'other' ]"
                                                                    outlined :readonly="readonly"
                                                                    :rules="[ $data.customRules.required ]"
                                                                >
                                                                    <template v-slot:selection="{ item }">
                                                                        {{ $t('eye_colors.' + item) }}
                                                                    </template>
                                                                    <template v-slot:item="{ item }">
                                                                        {{ $t('eye_colors.' + item) }}
                                                                    </template>
                                                                </v-select><!-- maxlength="10" -->
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-select
                                                                    :label="$t('pages.hiring.form.hair_color')"
                                                                    v-model="form.hair_color"
                                                                    :items="[ 'black', 'blond', 'brown', 'ginger', 'other' ]"
                                                                    outlined :readonly="readonly"
                                                                    :rules="[ $data.customRules.required ]"
                                                                >
                                                                    <template v-slot:selection="{ item }">
                                                                        {{ $t('hair_colors.' + item) }}
                                                                    </template>
                                                                    <template v-slot:item="{ item }">
                                                                        {{ $t('hair_colors.' + item) }}
                                                                    </template>
                                                                </v-select><!-- maxlength="10" -->
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-text-field
                                                                    :label="$t('pages.hiring.form.age')"
                                                                    v-model="form.age"
                                                                    v-mask="'##'"
                                                                    outlined :readonly="readonly"
                                                                    :rules="[ $data.customRules.required, $data.customRules.integer ]"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-text-field
                                                                    :label="$t('pages.hiring.form.bra_size')"
                                                                    v-model="form.bra_size"
                                                                    v-mask="'##AA'"
                                                                    outlined :readonly="readonly"
                                                                    :rules="[ $data.customRules.required ]"
                                                                    maxlength="4"
                                                                    :hint="$t('pages.hiring.form.bra_size_hint')" persistent-hint
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-tab-item>
                                                <v-tab-item v-if="isEditor">
                                                    <v-container fluid>
                                                        <v-row dense>
                                                            <template v-if="isAdminUser || !form.id">
                                                                <v-col cols="6">
                                                                    <v-text-field
                                                                        :label="$t('pages.hostesses.form.first_name')"
                                                                        v-model="form.first_name"
                                                                        outlined
                                                                        :rules="[ $data.customRules.required ]" maxlength="30"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-text-field
                                                                        :label="$t('pages.hostesses.form.last_name')"
                                                                        v-model="form.last_name"
                                                                        outlined
                                                                        :rules="[ $data.customRules.required ]" maxlength="30"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-text-field
                                                                        :label="$t('pages.hostesses.form.phone')"
                                                                        v-model="form.phone"
                                                                        v-mask="'(###) ###-####'"
                                                                        outlined
                                                                        :rules="[ $data.customRules.required, $data.customRules.phone ]"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-text-field
                                                                        :label="$t('pages.hostesses.form.email')"
                                                                        v-model="form.email"
                                                                        outlined
                                                                        :rules="[ $data.customRules.email ]" maxlength="200"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <v-textarea
                                                                        v-if="isJobApplication"
                                                                        :label="$t('pages.hostesses.form.experience')"
                                                                        v-model="form.experience"
                                                                        outlined counter="200" auto-grow rows="1"
                                                                        maxlength="200" readonly
                                                                    ></v-textarea>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <v-textarea
                                                                        v-if="isJobApplication"
                                                                        :label="$t('pages.hostesses.form.about_me')"
                                                                        v-model="form.about_me"
                                                                        outlined counter="200" auto-grow rows="1"
                                                                        maxlength="200" readonly
                                                                    ></v-textarea>
                                                                </v-col>
                                                            </template>
                                                            <v-col cols="12">
                                                                <v-textarea
                                                                    :label="$t('pages.hostesses.form.remark')"
                                                                    v-model="form.remark"
                                                                    outlined counter="2000" rows="3" auto-grow
                                                                    maxlength="2000"
                                                                ></v-textarea>
                                                            </v-col>
                                                            <v-col cols="12" v-if="isAdminUser && !isJobApplication && !!form.id">
                                                                <v-checkbox
                                                                    :label="$t('pages.hostesses.form.is_active')"
                                                                    v-model="form.is_active"
                                                                ></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="12" class="text-center" v-if="isJobApplication">
                                                                <div>{{ $t('pages.hostesses.form.rating') }}</div>
                                                                <v-rating
                                                                    v-model="form.rating"
                                                                    background-color="grey" clearable
                                                                    color="primary" length="5" size="32"
                                                                ></v-rating>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-tab-item>
                                                <v-tab-item v-if="!isJobApplication">
                                                    <v-container fluid>
                                                        <v-card v-for="(schedule, s) in form.schedules" :key="'schedule_' + s" class="mb-4">
                                                            <v-card-title>
                                                                {{ schedule.week_date }} — {{ schedule.to_date }}
                                                                <v-spacer v-if="isEditor"></v-spacer>
                                                                <v-btn v-if="isEditor" small @click="addSchedule(schedule)">
                                                                    <v-icon small left>mdi-plus</v-icon>{{ $t("add") }}
                                                                </v-btn>
                                                            </v-card-title>
                                                            <v-card-text v-if="!schedule.items || schedule.items.length === 0">
                                                                {{ $t("no-data") }}
                                                            </v-card-text>
                                                            <template v-if="isEditor">
                                                                <v-row dense v-for="(item, i) in schedule.items" :key="'schedule_' + s + '_item_' + i" class="px-4" :class="{ 'pb-4': i === schedule.items.length - 1 }">
                                                                    <v-col cols="6">
                                                                        <v-select
                                                                            :label="$t('dow.label')"
                                                                            v-model="item.dow"
                                                                            :items="days"
                                                                            :clearable="!!isAdminUser || parseInt(item.dow) === dow"
                                                                            :readonly="!isAdminUser && !!item.dow && parseInt(item.dow) !== dow"
                                                                        >
                                                                        <template v-slot:selection="{ item }">
                                                                            {{ $t('dow.' + item.value) }}
                                                                        </template>
                                                                        <template v-slot:item="{ item }">
                                                                            {{ $t('dow.' + item.value) }}
                                                                        </template>
                                                                        </v-select>
                                                                    </v-col>
                                                                    <v-col cols="3">
                                                                        <v-text-field
                                                                            :label="$t('dow.start')"
                                                                            v-model="item.start"
                                                                            v-mask="'##:##'"
                                                                            :rules="!!item.dow ? [ $data.customRules.required ] : []"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="3">
                                                                        <v-text-field
                                                                            :label="$t('dow.end')"
                                                                            v-model="item.end"
                                                                            v-mask="'##:##'"
                                                                            :rules="!!item.dow ? [ $data.customRules.required ] : []"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <!--<v-col cols="12">
                                                                        <v-select
                                                                            :label="$t('dow.availability')"
                                                                            v-model="item.availability"
                                                                            :items="availabilities"
                                                                            :readonly="!isAdminUser && !!item.dow && parseInt(item.dow) !== dow"
                                                                            :rules="!!item.dow ? [ $data.customRules.required ] : []"
                                                                        >
                                                                        <template v-slot:selection="{ item }">
                                                                            {{ $t('availabilities.' + item) }}
                                                                        </template>
                                                                        <template v-slot:item="{ item }">
                                                                            {{ $t('availabilities.' + item) }}
                                                                        </template>
                                                                        </v-select>
                                                                    </v-col>-->
                                                                </v-row>
                                                            </template>
                                                            <v-list-item two-line v-for="(day, d) in schedule.days" :key="'schedule_' + s + '_day_' + d">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        <v-chip v-for="(time, t) in day.items" :key="'schedule_' + s + '_day_' + d + '_time_' + t" outlined label small class="text-body-1 font-weight-bold mr-1 mb-1">
                                                                            <!--<v-icon left small>{{ time.availability == '1' ? 'mdi-home' : 'mdi-phone' }}</v-icon>-->
                                                                            {{ time.start }} - {{ time.end }}
                                                                        </v-chip>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>{{ $t('dow.' + day.dow) }}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-card>
                                                    </v-container>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider v-if="isEditor"></v-divider>
                                <v-card-actions v-if="isEditor">
                                    <v-btn text tile @click="dialog = false">{{ $t("close") }}</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" tile v-if="isAdminUser && !!item && (!item.is_active || isJobApplication)" @loading="removing" @click="remove">
                                        <v-icon left>mdi-delete</v-icon>{{ $t("delete") }}
                                    </v-btn>
                                    <v-btn tile color="primary" v-if="isAdminUser || isReceptionUser" type="submit" @loading="saving">
                                        <v-icon left>mdi-content-save</v-icon>{{ $t("save") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <!-- /dialog -->
                </v-card>
                <v-btn
                    v-if="button"
                    :large="$vuetify.breakpoint.smAndDown"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    tile absolute top right color="primary"
                    class="action"
                    @click="reset"
                >
                    <v-icon left>mdi-plus</v-icon>{{ $t("add") }}
                </v-btn>
            </div>
        </template>
    </v-hover>
</template>

<script>
    import ImageCarouselOverlay from "./ImageCarouselOverlay";

    export default {
        name: "hostesse-element",
        components: { ImageCarouselOverlay },
        props: {
            item: {
                type: Object,
                required: false
            },
            origin: {
                type: String,
                required: false
            },
            button: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data: () => ({
            fetching: false,
            dialog: false,
            saving: false,
            removing: false,

            tab: 0,
            form: {
                id: null,
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
                nationality_en: null,
                nationality_fr: null,
                height: null,
                weight: null,
                eye_color: null,
                hair_color: null,
                age: null,
                bra_size: null,
                description_en: null,
                description_fr: null,
                experience: null,
                about_me: null,
                rating: null,
                remark: null,
                is_active: false,

                photos: [],
                schedules: []
            },

            overlay: {
                value: false,
                current: null
            },

            defaults: null,
            dow: 0,

            heights: [
                { value: "408", text: "< 4'8\"" },
                { value: "409", text: "4'9\"" },
                { value: "410", text: "4'10\"" },
                { value: "411", text: "4'11\"" },
                { value: "500", text: "5'0\"" },
                { value: "501", text: "5'1\"" },
                { value: "502", text: "5'2\"" },
                { value: "503", text: "5'3\"" },
                { value: "504", text: "5'4\"" },
                { value: "505", text: "5'5\"" },
                { value: "506", text: "5'6\"" },
                { value: "507", text: "5'7\"" },
                { value: "508", text: "5'8\"" },
                { value: "509", text: "5'9\"" },
                { value: "510", text: "5'10\"" },
                { value: "511", text: "5'11\"" },
                { value: "600", text: "6'0\"" },
                { value: "601", text: "6'1\"" },
                { value: "602", text: "6'2\"" },
                { value: "603", text: "6'3\"" },
                { value: "604", text: "6'4\"" },
                { value: "605", text: "> 6'5\"" }
            ],
            availabilities: [ "1", "2" ]
        }),
        computed: {
            title() {
                return !!this.item && this.item.id > 0 ? 
                    (this.isJobApplication ? this.item.first_name + " " + this.item.last_name : this.item.nickname) : 
                    this.$i18n.t("pages.hostesses.new")
            },

            isJobApplication() {
                return !!this.item && this.item.id > 0 && !this.item.nickname;
            },

            isReceptionUser() {
                return !!this.$store.state.user && 
                    !!this.$store.state.user.type && 
                    this.$store.state.user.type == "reception";
            },
            isAdminUser() {
                return !!this.$store.state.user && 
                    !!this.$store.state.user.type && 
                    this.$store.state.user.type == "admin";
            },

            isEditor() {
                return this.isReceptionUser || this.isAdminUser;
            },

            readonly() {
                return !this.isAdminUser && !!this.item;
            },

            days() {
                let items = [];
                let dow = [ '1', '2', '3', '4', '5', '6', '7' ];
                for(let i = 0; i < dow.length; i++) {
                    items.push({
                        text: "",
                        value: dow[i],
                        disabled: !this.isAdminUser && parseInt(dow[i]) !== this.dow
                    });
                }
                return items;
            },

            height() {
                if(this.form.height) {
                    let h = this.heights.find(h => h.value == this.form.height);
                    return h.text;
                } else {
                    return null;
                }
            }
        },
        methods: {
            fetch() {
                if(this.fetching) {
                    return;
                }
                this.fetching = true;
                this._request("hostesses/get", { id: this.item.id }).then(response => {
                    if(response.error) {
                        this.$root.snack.show({
                            text: this.$i18n.t("error"),
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                        return;
                    }
                    
                    this.reset();
                    Object.assign(this.form, response.item);

                    if(!this.isEditor) {
                        // format schedules
                        let schedules = JSON.parse(JSON.stringify(this.form.schedules));
                        for(let s = 0; s < schedules.length; s++) {
                            let days = [];
                            for(let i = 0; i < schedules[s].items.length; i++) {
                                let day = days.find(d => d.dow == schedules[s].items[i].dow);
                                if(day) {
                                    day.items.push({
                                        start: schedules[s].items[i].start,
                                        end: schedules[s].items[i].end,
                                        availability: schedules[s].items[i].availability
                                    });
                                } else {
                                    days.push({
                                        dow: schedules[s].items[i].dow,
                                        items: [
                                            {
                                                start: schedules[s].items[i].start,
                                                end: schedules[s].items[i].end,
                                                availability: schedules[s].items[i].availability
                                            }
                                        ]
                                    });
                                }
                            }
                            schedules[s].days = days;
                        }
                        this.form.schedules = schedules;
                    }

                }).finally(() => {
                    this.fetching = false;
                });
            },

            reset() {
                this.tab = 0;
                this.form = JSON.parse(this.defaults);
                if(!!this.$refs && !!this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
                this.dialog = true;
                if(!this.item || !this.item.id) {
                    this.fetchSchedules();
                }
                if(!!this.$refs && !!this.$refs["dialog-content"]) {
                    this.$nextTick(() => {
                        this.$refs["dialog-content"].scrollTop = 0;
                    });
                }
            },

            save() {
                if(this.saving) {
                    return;
                }
                if(!this.$refs || !this.$refs.form || !this.$refs.form.validate() || this.form.photos.length === 0) {
                    this.$root.snack.show({
                        text: this.$i18n.t("form-validation-error"),
                        icon: "mdi-alert-circle",
                        color: "error"
                    });
                    return;
                }
                this.saving = true;
                this.form.origin = this.origin;
                this._request("hostesses/save", this.form).then(response => {
                    if(response.error) {
                        this.$root.snack.show({
                            text: this.$i18n.t("error"),
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                        return;
                    }

                    //console.log("response", response);
                    this.$emit("update", response);
                    this.dialog = false;

                    this.$root.snack.show({
                        text: this.$i18n.t("success"),
                        icon: "mdi-check",
                        color: "success"
                    });

                }).finally(() => {
                    this.saving = false;
                });
            },

            remove() {
                if(this.removing) {
                    return;
                }
                if(confirm(this.$i18n.t("delete-confirm"))) {
                    this.removing = true;
                    this._request("hostesses/delete", { id: this.form.id }).then(response => {
                        if(response.error) {
                            this.$root.snack.show({
                                text: this.$i18n.t("error"),
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                            return;
                        }

                        this.dialog = false;

                        this.$emit("update", this.form.id);

                        this.$root.snack.show({
                            text: this.$i18n.t("delete-success"),
                            icon: "mdi-check",
                            color: "success"
                        });

                    }).finally(() => {
                        this.removing = false;
                    });
                }
            },

            addPhotos() {
                let files = this.$refs.photos && this.$refs.photos.files ? this.$refs.photos.files : [];
                if(files.length == 0) {
                    return;
                }

                for(let i = 0; i < files.length; i++) {
                    let img = new Image();
                    img.onload = () => {
                        // Resize
                        let canvas = document.createElement("canvas");
                        let ctx = canvas.getContext("2d");
                        let scale = 1080 / img.height;
                        canvas.width = img.width * scale;
                        canvas.height = img.height * scale;
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        let data = canvas.toDataURL(files[i].type, 0.95);

                        this.form.photos.push(data);
                    };
                    img.src = window.URL.createObjectURL(files[i]);
                }
            },

            addSchedule(schedule) {
                if(!schedule.items || !Array.isArray(schedule.items)) {
                    schedule.items = [];
                }
                schedule.items.push({
                    dow: null,
                    start: null,
                    end: null
                });
            },
            fetchSchedules() {
                this._request("hostesses/get-schedules").then(response => {
                    if(response.error) {
                        this.$root.snack.show({
                            text: this.$i18n.t("error"),
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                        return;
                    }
                    
                    Object.assign(this.form, response);
                });
            }
        },

        created() {
            this.defaults = JSON.stringify(this.form);

            this.dow = new Date().getDay();
            if(this.dow == 0) {
                this.dow = 7;
            }
        }
    };
</script>

<style scoped>
    #wrapper {
        display: flex;
        flex-direction: column;
    }
    #header {
        flex: 0;
    }
    #content {
        flex: 1;
        padding: 0;
        position: relative;
    }
    .column {
        position: relative;
        height: 500px;
        overflow-y: auto;
    }

    .action {
        z-index: 4;
    }

    .overlay-action {
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        cursor: zoom-in;
    }

    .card {
        overflow: hidden;
        border: 1px solid #158ab9;
    }
    /*.diagonal-badge {
        white-space: nowrap;
        position: absolute;
        padding: 5px 100px;
        min-width: 440px;
        transform: rotate(36deg) translate(30%, 0);
        color: white;
        text-align: center;
        text-transform: uppercase;
        font-family: "antiga";
        font-size: 24px;
        font-weight: bold;
        right: 20px;
        box-sizing: border-box;
        z-index: 3;
    }*/

    .card .background::after {
        z-index: 1;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 1;

        background: linear-gradient(transparent 0%, #158ab9 90%);
    }
</style>