<template>
    <ContentLayout>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" lg="5" v-for="(room, i) in $t('pages.rates.rooms')" :key="i">
                <v-card elevation="8" color="white" tile>
                    <v-card-title class="text-h5 text-sm-h4 text-lg-h4 white--text secondary-font text-uppercase py-6 primary">
                        {{ $t(room.title) }}
                    </v-card-title>
                    <v-img
                        height="320"
                        :src="items[i].img"
                    ></v-img>
                    <v-container fluid class="pa-8 text-caption">
                        <v-row justify="center">
                            <v-col cols="6" sm="3" align="center" v-if="i == 1">
                                <v-icon color="primary" size="30">mdi-hot-tub</v-icon>
                                <div class="mt-4 text-uppercase">
                                    {{ $t('pages.rates.jacuzzi') }}
                                </div>
                            </v-col>
                            <v-col cols="6" sm="3" align="center">
                                <v-icon color="primary" size="30">mdi-sofa-single</v-icon>
                                <div class="mt-4 text-uppercase">
                                    {{ $t('pages.rates.sofa') }}
                                </div>
                            </v-col>
                            <v-col cols="6" sm="3" align="center">
                                <v-icon color="primary" size="30">mdi-shower-head</v-icon>
                                <div class="mt-4 text-uppercase">
                                    {{ $t('pages.rates.shower') }}
                                </div>
                            </v-col>
                            <v-col cols="6" sm="3" align="center">
                                <v-icon color="primary" size="30">mdi-creation</v-icon>
                                <div class="mt-4 text-uppercase">
                                    {{ $t('pages.rates.towels') }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
                    <v-row
                        v-for="(item, j) in room.items"
                        :key="j"
                        justify="center" align="center"
                        class="my-1 mx-2 secondary-font text-h5 text-sm-h4 text-uppercase"
                    >
                        <v-col cols="auto">
                            {{ item.title }}
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="primary--text">
                            $<span class="main-font text-h4 text-sm-h3">{{ item.amount }}</span>
                        </v-col>
                        <v-col
                            v-if="!!item.subtitle"
                            cols="12"
                            class="subtitle main-font text-caption"
                        >
                            {{ item.subtitle }}
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-btn color="primary" tile block large :to="{ name: 'contact' }">
                            {{ $t("reserve") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    export default {
        name: "rates-view",
        components: { ContentLayout },
        data: () => ({
            items: [
                {
                    img: "/assets/img/gallery/7.jpg"
                },
                {
                    img: "/assets/img/gallery/3.jpg"
                }
            ]
        })
    };
</script>

<style scoped>
    .subtitle {
        margin-top: -16px;
    }
</style>